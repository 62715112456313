<template>
    <div class="ui stackable grid m-0">
        <div class="row border-bottom">
            <div class="eight wide left aligned column">
                <h1 class="ui grey header"> {{isEdit ? 'Editer' : 'Nouvelle'}} Désignation Main d'oeuvre</h1>
            </div>
            <div class="eight wide right aligned column">
                <div class="ui primary tiny button" @click="save">
                    <i class="check circle icon"></i>
                    Enregistrer
                </div>
                <div class="ui red button" v-if="isEdit" @click="remove">
                    <i class="times circle icon"></i>
                    Supprimer
                </div>
                <div class="ui button" @click="$router.push({name: 'desigOeuvreList'})">
                    <i class="times circle icon"></i>
                    Cancel
                </div>
            </div>
        </div>

        <div class="row">
            <div class="column">
                    <div class="ui form">

                        <div class="eleven wide required field" style="margin-left: auto; margin-right: auto"
                             :class="{'error': $v.designation.label.$error}">
                            <label>Label</label>
                            <input type="text" placeholder="Label" v-model.trim="$v.designation.label.$model"
                                   v-uppercase>
                            <!-- error message -->
                            <div class="ui negative message" v-if="$v.designation.label.$error">
                                <p><i class="info circle icon"></i> Label est Obligatoire. </p>
                            </div>
                        </div>

                    </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { required } from 'vuelidate/lib/validators'
    export default {
        name: "designationCrud",
        props:{
            current_designation: {
                type: Object,
                default(){
                    return {}
                }
            }
        },
        data(){
          return{
              isEdit: false,
              designation: {
                  label: '',
                  type: 'main_oeuvre'
              }
          }
        },
        validations: {
            designation: {
                label: {required}
            }
        },
        methods:{
            save(){
                log('save designation:', this.designation);
                this.$v.$touch();
                if (this.$v.$invalid)
                    return;

                let data = {
                    collection: this.$SHARED.collections.designation,
                    query: this.designation
                };
                if(this.isEdit){ // update
                    let designation = JSON.parse(JSON.stringify(this.designation));
                    delete designation._id;
                    data.query = {
                        _id: this.designation._id
                    };
                    data.update = {'$set': designation}
                }
                let url = this.isEdit ? this.$SHARED.services.updateIfNotExist
                                        : this.$SHARED.services.insertIfNotExist;

                this.$store.dispatchAsync(url, data).then(() => {
                    this.$router.push({name: 'desigOeuvreList'});
                    this.$notify({
                        group: 'user-message',
                        type: 'success', // warn , error, success, info
                        text: this.$SHARED.messages.designation[this.isEdit ? 'update_succeeded' : 'create_succeeded']
                    });
                }, code => {
                    let message = this.$SHARED.messages.technical_error;
                    if(code === 7){
                        message = "Désignation " + this.$SHARED.messages["7"]
                    }
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: message
                    });
                });
            },
            remove(){
                if(this.isPreDefined){
                    return;
                }
                let data = {
                    collection: this.$SHARED.collections.designation,
                    query: {
                        _id: this.designation._id
                    }
                };
                this.$store.requestDelete(data).then(() => {
                    this.$router.push({name: 'desigOeuvreList'});
                    this.$notify({
                        group: 'user-message',
                        type: 'success', // warn , error, success, info
                        text: this.$SHARED.messages.designation.delete_succeeded
                    });
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: this.$SHARED.messages.technical_error
                    });
                });
            }
        },
        mounted() {
            if(this.current_designation.hasOwnProperty('_id')){
                this.isEdit = true;
                this.designation = JSON.parse(JSON.stringify(this.current_designation));
            }
        }
    }
</script>

<style scoped>
    .border-bottom{
        border-bottom: 2px solid #767676;
    }
</style>